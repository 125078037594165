<template>
  <div v-if="type === 'prepend'">
    <div class="session-info-title" v-if="text1 != ''">
      <b>{{ title1 }}</b> {{ text1 }}
    </div>
    <div class="session-info-title" v-if="text2 != ''">
      <b>{{ title2 }}</b> {{ text2 }}
    </div>
  </div>
  <div v-else>
    <div class="session-info-title" v-if="text1 != ''">
      <b>{{ text1 }}</b> {{ title1 }}
    </div>
    <div class="session-info-title" v-if="text2 != ''">
      <b>{{ text2 }}</b> {{ title2 }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwoLines',
  props: {
    type: {
      type: String,
      default: 'append',
    },
    title1: {
      type: String,
      default: '',
    },
    text1: {
      type: String,
      default: '',
    },
    title2: {
      type: String,
      default: '',
    },
    text2: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.session-info-title {
  font-size: 0.9rem;
}
.session-info-subtitle {
  font-size: 0.75rem;
}
</style>
