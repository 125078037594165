<template>
  <v-container id="Tarifas" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-currency-usd"
      inline
      :title="$t('rates')"
    >
      <v-spacer></v-spacer>

      <!-- Boton nuevo registro -->
      <template v-slot:after-heading></template>
      <template v-slot:corner-button>
        <v-btn color="primary" elevation="2" small class="ma-2" @click.stop="dialog = true"
          >Nueva Tarifa</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Textfield para la barra de busqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo cancelar -->
      <ConfirmationDialog
        buttonColor1="error"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Dialogo Nuevo Agregar/Editar Tarifa -->
      <v-dialog max-width="600px" persistent v-model="dialog">
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->
          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- Aeropuerto -->
                <v-col cols="12">
                  <v-select
                    dense
                    label="Aeropuerto*"
                    item-text="aeropuerto"
                    item-value="iata"
                    persistent-hint
                    required
                    v-model="editedItem.aeropuerto"
                    :items="aeropuertos"
                    :menu-props="{ top: true, offsetY: true }"
                    @change="onTextChange"
                  ></v-select>
                </v-col>

                <!-- min_pax -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Mínimo Pasajeros*"
                    max="99"
                    maxlength="2"
                    min="0"
                    required
                    suffix="pax"
                    type="number"
                    v-model="$v.editedItem.min_pax.$model"
                    :error-messages="min_paxErrors"
                    @blur="$v.editedItem.min_pax.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.min_pax.$reset()"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>

                <!-- max_pax -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Máximo Pasajeros*"
                    max="99"
                    maxlength="2"
                    min="0"
                    required
                    suffix="pax"
                    type="number"
                    v-model="$v.editedItem.max_pax.$model"
                    :error-messages="max_paxErrors"
                    @blur="$v.editedItem.max_pax.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.max_pax.$reset()"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>

                <!-- Tipo Vehículo -->
                <v-col cols="6">
                  <v-select
                    dense
                    label="Tipo Vehículo*"
                    item-text="traduccion"
                    item-value="_id"
                    persistent-hint
                    required
                    v-model="editedItem.tipo_vehiculo"
                    :items="tipos_vehiculo"
                    :menu-props="{ top: true, offsetY: true }"
                    @change="onTextChange"
                  ></v-select>
                </v-col>

                <!-- Tipo Traslado -->
                <v-col cols="6">
                  <v-select
                    dense
                    item-text="tipo"
                    item-value="_id"
                    label="Tipo Traslado*"
                    persistent-hint
                    required
                    v-model="editedItem.tipo_traslado"
                    :items="tipos_traslado"
                    :menu-props="{ top: true, offsetY: true }"
                    @change="onTextChange"
                  ></v-select>
                </v-col>

                <!-- Zona -->
                <v-col cols="6">
                  <!-- v-model="editedItem.direccion.zona" -->
                  <v-select
                    dense
                    item-text="zona"
                    item-value="_id"
                    label="Zona*"
                    persistent-hint
                    required
                    return-object
                    v-model="editedItem.zona"
                    :items="zonas"
                    :menu-props="{ top: true, offsetY: true }"
                    @change="onTextChange"
                  ></v-select>
                </v-col>

                <!-- Campo Precio -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    hint="Registrar Precio"
                    label="Precio*"
                    maxlength="20"
                    prefix="$"
                    required
                    v-model="$v.editedItem.precio.$model"
                    :error-messages="precioErrors"
                    @blur="$v.editedItem.precio.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.precio.$reset()"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>

                <!-- Fecha Inicio Validez -->
                <v-col cols="6">
                  <v-layout row wrap>
                    <v-menu
                      max-width="290px"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                      v-model="fromDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          dense
                          label="Válido desde*"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-on="on"
                          :value="fromDateDisp"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="en-in"
                        no-title
                        v-model="editedItem.desde"
                        :min="minDate"
                        :max="maxDateDisp"
                        @change="onTextChange"
                        @input="fromDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-layout>
                </v-col>

                <!-- Fecha Final Validez -->
                <v-col cols="6">
                  <v-layout row wrap>
                    <v-menu
                      max-width="290px"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                      v-model="toDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          dense
                          label="Válido Hasta"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-on="on"
                          :value="toDateDisp"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="en-in"
                        no-title
                        v-model="editedItem.hasta"
                        :min="minDate"
                        :max="maxDateDisp"
                        @change="onTextChange"
                        @input="toDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-layout>
                </v-col>
              </v-row>
            </v-container>
            <small>*Campo obligatorio</small>
          </v-card-text>

          <!-- Botones y Crear Otro -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
              :disabled="isEditing"
            ></v-checkbox>

            <!-- Botones de guardar y cancelar -->
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="save" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Tabla de datos de tarifas -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="tarifas"
        :loading="isLoading"
        :search.sync="search"
      >
        <!-- Slot de pax -->
        <template v-slot:[`item.min_pax`]="{ item }">
          <TwoLines
            :type="`prepend`"
            :title1="`Mínimo: `"
            :text1="`${item.min_pax}`"
            :title2="`Máximo:`"
            :text2="`${item.max_pax}`"
          />
        </template>

        <!-- Slot de fechas-->
        <template v-slot:[`item.desde`]="{ item }">
          <DateFormat :begin="item.desde" :end="item.hasta" />
        </template>

        <!-- Slot actions editar y eliminar -->
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <!-- Slot del boton de Activo-->
        <template v-slot:item.activo="{ item }">
          <Status :activo="item.activo" />
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!</template
        >
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="tarifas" :dataFields="fields" fileName="Tarifas" />
    </base-material-card>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import DateFormat from '@/components/general/DateFormat.vue'
import Status from '@/components/general/StatusInfo.vue'
import TwoLines from '@/components/general/TwoLines.vue'

export default {
  name: 'Tarifas',
  components: {
    ConfirmationDialog,
    DataExporter,
    DateFormat,
    Status,
    TwoLines,
  },

  data: () => ({
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    fromDateMenu: false,
    isEditing: false,
    isLoading: false,
    isSure: false,
    maxDate: '2021-09-20',
    minDate: new Date().toISOString().substr(0, 10),
    overlay: false,
    search: undefined,
    toDateMenu: false,
    aeropuertos: [],
    headers: [
      { text: 'Número Pasajeros', align: 'start', sortable: false, value: 'min_pax' },
      {
        text: 'Tipo Vehículo',
        align: 'start',
        sortable: false,
        value: 'traducciones.es.tipo_vehiculo',
      },
      { text: 'Tipo Traslado', value: 'traducciones.es.tipo_traslado' },
      { text: 'Zona', align: 'start', sortable: false, value: 'zona.zona' },
      { text: 'Precio', value: 'precio' },
      { text: 'Vigencia', value: 'desde' },
      { text: 'Aeropuerto', value: 'aeropuerto' },
      // { text: 'Hasta', value: 'hasta' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    tarifas: [],
    tipos_traslado: [],
    tipos_vehiculo: [],
    value: [],
    zonas: [],
    defaultItem: {
      _id: '',
      min_pax: '',
      max_pax: '',
      tipo_vehiculo: '',
      tipo_traslado: '',
      zona: '',
      precio: '',
      desde: null,
      hasta: null,
      activo: true,
      aeropuerto: '',
    },
    editedItem: {
      _id: '',
      min_pax: '',
      max_pax: '',
      tipo_vehiculo: '',
      tipo_traslado: '',
      zona: '',
      precio: '',
      desde: '',
      hasta: '',
      aeropuerto: '',
      activo: true,
    },
    fields: {
      MinPax: 'min_pax',
      MaxPax: 'max_pax',
      TipoVehiculo: 'tipo_vehiculo.tipo',
      TipoTraslado: 'tipo_traslado',
      Zona: 'zona.zona',
      Precio: 'precio',
    },
  }),

  mixins: [validationMixin],
  validations: {
    editedItem: {
      min_pax: { required, minLength: minLength(1), maxLength: maxLength(3) },
      max_pax: { required, minLength: minLength(1), maxLength: maxLength(3) },
      precio: { required, minLength: minLength(2), maxLength: maxLength(20) },
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nueva Tarifa' : 'Editar Tarifa'
    },
    fromDateDisp() {
      return this.editedItem.desde
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },

    /**
     * @name maxDateDisp
     *
     * @description Calcula la fecha máxima para poder realizar una reserva
     * en este caso en particular un año posterior a la fecha de la reserva
     *
     * @return {date} fecha un año posterior a la fecha actual
     */
    maxDateDisp() {
      const date = new Date() //.toISOString().substr(0, 10)
      const year = date.getFullYear() + 1
      const month = date.getMonth() + 1
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return year + '-' + (month < 10 ? '0' + month : month) + '-' + day
    },

    max_paxErrors() {
      const errors = []
      if (!this.$v.editedItem.max_pax.$dirty) return errors
      !this.$v.editedItem.max_pax.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.max_pax.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 caracteres.')
      !this.$v.editedItem.max_pax.maxLength &&
        errors.push('Este campo debe de tener un máximo de 3 caracteres.')
      return errors
    },

    min_paxErrors() {
      const errors = []
      if (!this.$v.editedItem.min_pax.$dirty) return errors
      !this.$v.editedItem.min_pax.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.min_pax.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 caracteres.')
      !this.$v.editedItem.min_pax.maxLength &&
        errors.push('Este campo debe de tener un máximo de 3 caracteres.')
      return errors
    },

    precioErrors() {
      const errors = []
      if (!this.$v.editedItem.precio.$dirty) return errors
      !this.$v.editedItem.precio.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.precio.minLength &&
        errors.push('Este campo debe de tener un mínimo de 2 caracteres.')
      !this.$v.editedItem.precio.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
    toDateDisp() {
      return this.editedItem.hasta
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.obtenerAeropuertos()
    this.obtenerZonas()
    this.obtenerTiposVehiculo()
    this.obtenerTiposTraslado()
    this.obtenerTarifas()
  },

  methods: {
    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let body = {
        _id: this.editedItem._id,
      }
      let payload = { body: body }
      payload.id = this.editedItem._id
      this.$store
        .dispatch('rate/deleteRate', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se elimino con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description Verifica si esta seguro de salir del dialogo
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Verifica si cambio algun campo
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el dialogo
     * @param {bool} checkbox boleano que indica el estado del checkbox
     */
    close(checkbox) {
      this.isSure = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!checkbox) {
        this.dialog = false
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description Convierte una fecha a numeros formato 99999999
     */
    convierteFechaNumero(date) {
      const fecha = new Date(date)
      const year = fecha.getFullYear().toString()
      const month =
        fecha.getMonth().toString().length == 1
          ? '0' + (fecha.getMonth() + 1).toString()
          : fecha.getMonth().toString()
      const day =
        fecha.getDate().toString().length == 1
          ? '0' + fecha.getDate().toString()
          : fecha.getDate().toString()
      const hoy = year.toString() + month + day.toString()
      return parseFloat(hoy)
    },

    /**
     * @description Convierte un numero formato 99999999 en una fecha
     */
    convierteNumeroFecha(date) {
      if (date == 99999999) {
        return null
      }
      const cadena = date.toString()
      let year = cadena.slice(0, 4)
      let month = cadena.slice(4, 6)
      let day = cadena.slice(6, 8)
      const fecha = year + '-' + month + '-' + day

      return fecha
    },

    /**
     * @description Elimina una tarifa
     * @param {object} item elemento seleccionado
     */
    deleteItem(item) {
      const index = this.tarifas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.tarifas.splice(index, 1)
      }
    },

    /**
     * @description Obtiene el indice del elemento seleccionado y lo signa al array editedItem
     * @param {object} item elemento seleccionado
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.tarifas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.desde = this.convierteNumeroFecha(item.desde)
      this.editedItem.hasta = this.convierteNumeroFecha(item.hasta)
      this.dialog = true
    },

    /**
     * @description Evalua el caracter tecleado para saber si es un numero
     */
    isNumber: function (evt, negativo = false) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode

      if (negativo && charCode === 45) {
        return true
      }

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 //&&
        // charCode !== 45
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    /**
     * @description Obtiene los aeropuertos
     * @return {array} arreglo con todos los aeropuertos
     */
    obtenerAeropuertos() {
      let payload = {}
      this.$store
        .dispatch('airports/fetchAirports', payload)
        .then((response) => {
          this.aeropuertos = response.data.map((airport) => ({
            _id: airport._id,
            aeropuerto: airport.aeropuerto,
            iata: airport.iata,
          }))
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Obtiene las tarifas
     * @return {array} arreglo con todas las tarifas
     */
    obtenerTarifas() {
      let payload = {}
      this.$store
        .dispatch('rate/fetchRate', payload)
        .then((response) => {
          this.tarifas = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Obtiene los tipos de traslados
     * @param {string} jwt JSON Web Token Valido
     * @return {array} arreglo con todas los tipos de traslados
     */
    obtenerTiposTraslado() {
      let payload = {}
      this.$store
        .dispatch('transfer/fetchTransferTypes', payload)
        .then((response) => {
          this.tipos_traslado = response.data
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene un listado de los tipos de vehiculos
     * @param {string} jwt JSON Web Token Valido
     * @return {array} arreglo con todas los tipos de vehiculos
     */
    obtenerTiposVehiculo() {
      let payload = { params: '?campo1=tipo&orden1=1' }
      this.$store
        .dispatch('vehicleType/fetchVehicleTypes', payload)
        .then((response) => {
          this.tipos_vehiculo = response.data
          this.tipos_vehiculo.forEach((element) => {
            element.traduccion =
              process.env.VUE_APP_I18N_LOCALE === 'en'
                ? element.traducciones.en.tipo
                : element.traducciones.es.tipo
          })
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene un listado de las zonas geograficas
     * @param {string} jwt JSON Web Token Valido
     * @return {array} arreglo con todas las zonas
     */
    obtenerZonas() {
      this.isLoading = true
      let payload = { params: '?campo1=zona&orden1=1' }
      this.$store
        .dispatch('zone/fetchZones', payload)
        .then((response) => {
          this.zonas = response.data
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    /**
     * @description Guarda el contenido del dialogo
     */
    async save() {
      if (parseInt(this.editedItem.max_pax) <= parseInt(this.editedItem.min_pax)) {
        this.$store.commit('ALERT', {
          color: 'error',
          text: 'El número máximo de pasajeros debe ser mayor al número mínimo de pasajeros establecidos.',
        })
        return
      }
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const desde = this.convierteFechaNumero(this.editedItem.desde)
        const hasta = this.editedItem.hasta
          ? this.convierteFechaNumero(this.editedItem.hasta)
          : 99999999

        let body = {
          min_pax: this.editedItem.min_pax,
          max_pax: this.editedItem.max_pax,
          tipo_vehiculo: this.editedItem.tipo_vehiculo,
          tipo_traslado: this.editedItem.tipo_traslado,
          // zona: this.editedItem.direccion.zona._id,
          zona: this.editedItem.zona._id,
          aeropuerto: this.editedItem.aeropuerto,
          precio: this.editedItem.precio,
          desde: desde,
          hasta: hasta,
        }
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('rate/editRate', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizó con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
          this.editedItem.desde = desde
          this.editedItem.hasta = hasta
          Object.assign(this.tarifas[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('rate/addRate', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.tarifas.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err,
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
        this.changed = false
      }
    },
  },
}
</script>
